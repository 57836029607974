.age-search {
  display: flex;
  color: #aeaeae;
  flex-direction: row;
  font-size: 15px;
  font-family: 'BrandonGrotesque-Bold';
  gap: 20px;

  .MuiSlider-root {
    color: $type-gray;
  }

  &__mark {
    margin-top: 8px;
  }

  &__slider {
    $track-height: 2px;
    $thumb-width: 23px;
    $track-top-offset: 5px;
    
    margin-left: -10px;
  
    .MuiSlider-track, .MuiSlider-rail {
      height: $track-height;
      margin-top: $track-top-offset
    }
    .MuiSlider-thumb {
      height: $thumb-width;
      width: $thumb-width;
    }
    .MuiSlider-valueLabel {
      margin-left: 10.5px;
    }
  }
}
