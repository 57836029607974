.sub-header-container {
    display: flex;
    background-color: $joy-red;
    flex-direction: column;
    margin-bottom: 130px;
    padding-top: 3vh;
    padding-left: 4vw;
    padding-right: 4vw;
    width: 100vw;

    @include layout-breakpoint('L') {
      flex-direction: row;
      padding: 5vh 11vw;
      margin-bottom: 60px;
    }

    .header-text-box {
        align-items: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        @include layout-breakpoint('L') {
          align-items: flex-start;
          flex: 1;
          text-align: left;
        }
        
        .heading-text {
            font-size: clamp(32px, 3.5vw, 40px);
            color: $white;
            font-family: 'BrandonGrotesque-Bold';
            line-height: 1;

            &__org-name {
                @extend .heading-text;
                width: 65vw;
                white-space: normal;
                overflow-wrap: break-word;
                text-align: center;
            }
        }

        .express-sponsorship-button {
            all: unset;
        }
        
        .heading-text-two {
            font-size: clamp(16px, 2vw, 20px);
            font-weight: bold;
            color: $white;
            font-family: 'BrandonGrotesque-Thin';           
        }

        .heading-text-three {
            font-size: 15px;
            color: $white;
            font-family: 'BrandonGrotesque-Thin'; 
            text-decoration: underline;
            cursor: default;

            &--disabled {
                @extend .heading-text-three;
                cursor: pointer;
            }
        }

        .button-container {
            margin-top: 1rem;
            margin-bottom: 1rem;

            button {
                display: flex;
                background-color: $white;
                color: $joy-red;
            }
        }
    }

    .header-image-box {
      margin-top: 40px;
      margin-bottom: -120px;
      position: relative;
      margin-left: auto;
      margin-right: auto;

      @include layout-breakpoint('L') {
        margin: 0;
      }

      .image-hero-box {
        @include layout-breakpoint('L') {
          position: absolute;
          right: 0px;
          top: 15px;

          &--lowered {
            top: 60px;
          }
        }

        .image-hero-box__image {
          height: 100%;
          max-width: 500px;
          width: 100%;

          @include layout-breakpoint('L') {
            height: unset;
            width: 40vw;
          }
        }
      }

        .ornament-box {
            display: flex;
            flex-direction: row;
            top: -60px;
            left: -125px;
            position: absolute;
            visibility: hidden;

            @include layout-breakpoint('L') {
              visibility: visible;
            }

            .red-ornament {
                position: relative;
                right: 20px;
                top: -430px;
                max-width: 100%;
                height: auto;

                &__is-national-org {
                    @extend .red-ornament;
                    top: -500px;
                }
            }
            .blue-ornament {
                position: relative;
                max-width: 100%;
                height: auto;
                top: -30px;

                &__is-national-org {
                    @extend .blue-ornament;
                    top: -80px;
                }
            }
        }

        .bless-a-child {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -20px;
            left: 20px;
            border-radius: 50%;
            background-color: #58C4C7;
            height: 100px;
            width: 100px;

            @include layout-breakpoint('L') {
              top: 100px;
              left: -50px;
              bottom: unset;
            }

            .bless-a-child__sticker {
                text-align: center;
                position: relative;
                top: 5px;

                p {
                    color: $white;
                    font-family: 'BrandonGrotesque-Bold';
                    text-align: center;
                    margin: 0;
                    font-size: 18px;
                    line-height: 1;
                }

                .donation-amount {
                    font-size: 34px;
                }
            }
        }
    }
        
}
