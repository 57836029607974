.btn {
  position: relative;
  font-size: 16px !important;
  min-height: 3em;
}

.btn-primary {
  background: #167ffb !important;
}

.btn-secondary {
  background: #6c757d !important;
}

.btn-danger {
  background: #d93749 !important;
}

.btn-outline-secondary {
  border: 1px solid #6c757d !important;
}

.btn-outline-danger {
  border: 1px solid #d93749 !important;
}

.btn-outline-info {
  border: 1px solid #24a2b6 !important;
}

.btn-outline-primary {
  border: 1px solid #167ffb !important;
}

.bg {
  background: linear-gradient(
    145deg,
    rgba(33, 150, 243, 1) 0,
    rgba(15, 115, 194, 1) 50%,
    rgba(0, 101, 181, 1) 100%
  ) !important;
}

.button-loading-icon {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
}
.red-btn {
  background: $primary-color;
  border-color: $primary-color;
  color: white;
  border-width: 2px;
  width: 180px;
  min-height: 37px;
  border-radius: 5px;
  font-size: 1em;
  font-family: $primary-font-family;
  font-weight: bold;
}
.block-btn {
  background: $primary-color;
  color: white;
  height: 42px;
  font-size: 1.2em;
  font-family: $primary-font-family;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
  font-weight: bold;
  text-decoration: underline;
}
.btn-round-bottom {
  height: 84px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.common.btn-style {
  display: inline-block;
  border: solid 2px white;
  background: $primary-color;
  padding: 0.4em 3em;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  font-family: $primary-font-family;
}

.next-btn {
  border-bottom-right-radius: 2em;
  border-top-right-radius: 2em;
  @extend .common, .btn-style;
}

.rounded-btn {
  border-bottom-left-radius: 1em;
  border-top-left-radius: 1em;
  border-bottom-right-radius: 1em;
  border-top-right-radius: 1em;
  font-size: 1.25rem;
  @extend .common, .btn-style;
}

.btn-img {
  width: 65px;
}

.btn-img-text {
  flex: 1;
}

.button:focus {
  outline: unset !important;
}
