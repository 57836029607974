// NOTE :: Keep in-sync with src/constants/app-consts.ts::layoutBreakpoints values
$breakpoints: (
  'S':  (screen and min-width: 320px), // Small, e.g. mobile
  'M': (screen and min-width: 481px), // Medium, e.g. tablet
  'L':  (screen and min-width: 769px), // Large, e.g. desktop
) !default;

@mixin layout-breakpoint($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    // Gets media query condition based on the given breakpoint value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Invalid breakpoint value: `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
