.child-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-top: 10px;
  padding-left: 9px;
  margin: 0.8em 0;

  @include layout-breakpoint('L') {
    flex-grow: initial;
    margin: 2em 0.8rem;
  }

  .error {
    height: 29px;
    padding-left: 8px;
    padding-top: 3px;
  }

  .icon {
    background: #e2e2e2;
    padding-top: 8px;
    margin-top: -3px;
    width: 28px;
    height: 27px;
    border: none;
    color: black;
  }
}

.child-card {
  $background-color: $tertiary-color;
  cursor: pointer;
  height: 168px;
  border: 0px solid $type-gray;
  box-shadow: 0 8px 4px -4px hsla(0, 0%, 0%, 0.15);
  border: 2px solid #dfdfdf;
  border-radius: 20px;
  flex-grow: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 75px;
  padding-right: 2vw;

  @include layout-breakpoint('L') {
    align-items: center;
    flex-grow: initial;
    height: 307px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;
    width: 300px;
  }

  .selection-btn {
    @extend .block-btn;
    @extend .btn-round-bottom;
  }
}

.checked-card {
  border-color: $secondary-color;
  box-shadow: none;
}

.checked-block-btn {
  background: $primary-color;
  &:hover {
    background: $primary-color;
  }
}

.message-container {
  justify-content: left;
  margin-bottom: 0;
  display: flex;
  flex: 1;
  text-align: left;
  font-style: italic;
  font-family: $primary-font-family;
  font-size: 16px;
  color: $type-gray;
  overflow: hidden;

  @include layout-breakpoint('L') {
    text-align: center;
    margin-bottom: 1rem;
    width: 16rem;
  }

  .parent-message-title {
    text-transform: uppercase;
  }

  .parent-message {
    overflow: hidden;
    display: contents;
  }
}

.child-info {
  display: flex;
  align-items: start;
  flex-direction: column;
  margin: 10px 0 10px;
  text-align: center;
  width: 100%;

  @include layout-breakpoint('L') {
    align-items: center;
  }
}

.child-details {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $type-gray;
  font-family: 'BrandonGrotesque-Bold', Arial, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  width: 100%;

  @include layout-breakpoint('L') {
    text-align: center;
  }
}

.selection-icon {
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 25px;
}

.child-header-image {
  width: 80px;
  position: absolute;
  top: 10px;
  left: 0;
  box-shadow: 0 0 1px -3px $primary-color;
  border-radius: 40px;

  @include layout-breakpoint('L') {
    top: -30px;
    left: initial;
  }
}

.child-name {
  font-family: 'Oswald';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 30px;
  color: $secondary-color;
  text-transform: uppercase;
  text-align: left;

  @include layout-breakpoint('L') {
    font-size: 37px;
    text-align: center;
  }
}
