.gender-filter {
  display: flex;
  flex-direction: row;

  .MuiTypography-body1 {
    color: $type-gray;
    font-size: 20px;
    font-family: 'BrandonGrotesque';
  }
}
