.text-message-style {
  width: 100%;
  height: 100%;
  background: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-family: $primary-font-family;
  font-size: $fs-lg-h4;
  color: white;
  text-align: center;

  .text-style {
    width: 50%;
    @media only screen and (max-width: 575px) {
      width: 100%;
      padding: 0 10px;
    }
  }
}
