@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url(../fonts/Oswald-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald-Bold';
  src: local('Oswald'), url(../fonts/Oswald-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald-Light';
  src: local('Oswald'), url(../fonts/Oswald-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald-SemiBold';
  src: local('Oswald'), url(../fonts/Oswald-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald-Medium';
  src: local('Oswald'), url(../fonts/Oswald-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Oswald-ExtraLight';
  src: local('Oswald'), url(../fonts/Oswald-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'PermanentMarker';
  src: local('PermanentMarker'),
    url(../fonts/PermanentMarker.ttf) format('truetype');
}

@font-face {
  font-family: 'JennaSue';
  src: local('JennaSue'), url(../fonts/JennaSue.ttf) format('truetype');
}

@font-face {
  font-family: 'BrandonGrotesque';
  src: local('BrandonGrotesque'), url(../fonts/BrandonGrotesque-Regular.otf) format('OpenType');
}

@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: local('BrandonGrotesque'), url(../fonts/BrandonGrotesque-Bold.otf) format('OpenType');
}

@font-face {
  font-family: 'BrandonGrotesque-Light';
  src: local('BrandonGrotesque'), url(../fonts/BrandonGrotesque-Light.otf) format('OpenType');
}

@font-face {
  font-family: 'BrandonGrotesque-Black';
  src: local('BrandonGrotesque'), url(../fonts/BrandonGrotesque-Black.otf) format('OpenType');
}

@font-face {
  font-family: 'BrandonGrotesque-Medium';
  src: local('BrandonGrotesque'), url(../fonts/BrandonGrotesque-Medium.otf) format('OpenType');
}

@font-face {
  font-family: 'BrandonGrotesque-Thin';
  src: local('BrandonGrotesque'), url(../fonts/BrandonGrotesque-Thin.otf) format('OpenType');
}
