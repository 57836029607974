.main-layout {
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: cover;
  flex-shrink: 1;
  @media only screen and (max-width: 1023px) {
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media only screen and (max-width: 575px) {
    background-size: 100% 20%;
  }

  .header-container {
    justify-content: space-between;
    padding-top: 20px;

    @media only screen and (max-width: 1023px) {
      padding: 10px 0;
    }

    .vat-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .logo {
        height: 120px;
        width: 120px;
        margin: 0 8em;
        object-fit: cover;

        @media only screen and (max-width: 575px) {
          margin-left: 25px;
          padding: 0;
        }

        @media only screen and (max-width: 375px) {
          margin-left: 15px;
        }

        @media only screen and (max-width: 320px) {
          margin-left: 5px;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .main-layout {
    padding-left: 0;
  }
}

.remove-bg {
  background: none;
}
