.login-field {
  width: 175px !important;

  .form-control {
    width: 100%;
    border: none !important;
    border-bottom: 2px solid $primary-color !important;
    margin-top: -7px;

    &:focus {
      outline: 0 !important;
    }
  }
}

.input-group {
  .form-control {
    border-radius: 0 !important;
    width: 100%;
    font-family: $primary-font-family;
    border: none;
    border-bottom: 1px solid $type-gray;
    font-size: $fs-p3 !important;

    &::placeholder {
      font-weight: bold;
      font-size: $fs-p6;
      text-align: left;
      text-transform: uppercase;
      color: $type-gray;
    }
  }

  .invalid-feedback {
    text-align: left;
    font-size: $fs-p5;
    $background-color: $tertiary-color;
  }

  .is-invalid {
    background-image: unset !important;
  }
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.input-label {
  font-family: $primary-font-family;
  font-weight: 400;
  color: $primary-color;
  font-size: $fs-p5;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .input-group {
    width: 100% !important;
  }
}
