.donation-total{
  font-family: $primary-font-family;
  font-size: 25px;
  font-weight: 700;
  padding: 15px 0px;
  text-transform: uppercase;
  color:black;
  text-align: center;
}
.rbp-card {
  width: 100%;
  border: solid 4px $primary-color;
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 465px;

  @media only screen and (max-width: 768px) {
    border: unset;
  }

  .rbp-card-content {
    background: white;
    text-align: center;
    padding: 15px 0;
    width: 40%;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    .border-container {
      box-shadow: 0 0 5px 2px #d51c28;

      @media only screen and (max-width: 480px) {
        box-shadow: none;
      }
    }
  }
}

.rbp-footer {
  .common-content-style {
    font-family: $primary-font-family;
    color: $secondary-color;
    text-transform: uppercase;
  }

  .common.btn-style {
    background: $secondary-color;
  }

  .button {
    max-width: 160px;
  }

  .button:focus {
    outline: unset !important;
  }

  .button:disabled {
    background: gray !important;
    &:hover {
      background: gray !important;
    }
  }

  .next-btn {
    padding: 5px 23px !important;

    @media(min-width: 468px) {
      margin: 0 0 0 15px;
      padding: 5px 25px !important;
    }
  }

  .back-btn {
    background: none;
    border: none;
    color: $primary-color;
    font-family: $primary-font-family;
    opacity: .75;
    font-size: 14px;

    @media(min-width: 468px) {
      margin: 0 15px 0 0;
    }
  }

  .donation-total {
    margin: auto;
    @media only screen and (min-width: 769px) {
      width: 170px;
    }

    .content-label {
      @extend .common-content-style;
      font-weight: 300;
      font-size: $fs-p3;
      @media only screen and (max-width: 768px) {
        font-size: $fs-p1;
        color: $secondary-color;
      }
    }

    .content-value {
      @extend .common-content-style;
      font-weight: bold;
      font-size: $fs-h3;
      @media only screen and (max-width: 768px) {
        font-size: $fs-h1;
        color: $secondary-color;
      }
    }
  }

  .common.btn-style {
    padding: 0.4em 1.6em;

    @media only screen and (max-width: 768px) {
      background: $secondary-color;
    }
  }
}
