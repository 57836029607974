.divider {
    width: 100%;
}

.filter-container {
  width: 100%;

  @include layout-breakpoint('L') {
    align-content: center;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: space-between;
  }
}

.filter-message {
  flex: 2 auto auto;
  min-width: 180px;
  padding-right: 10px;

  &__title {
    color: $secondary-color;
    font-family: 'BrandonGrotesque-Bold';
    font-size: 25px;
    padding: 0 8vw;
    text-align: center;

    @include layout-breakpoint('L') {
        font-size: clamp(24px, 2.5vw, 32px);
        padding: 0;
        text-align: left;
        padding-top: 5vh;
    }
  }

  &__text {
    color: black;
    font-family: 'BrandonGrotesque';
    font-size: clamp(16px, 2vw, 20px);
  }
}

.search-children-age-filter {
  max-width: 220px;
  min-width: 200px;

  @include layout-breakpoint('L') {
    max-width: 375px;
    min-width: 220px;
  }
}

.search-children-gender-filter {
  margin-bottom: -14px;

  @include layout-breakpoint('L') {
    flex: 1 0 auto;
  }
}

.search-container {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  width: 100%;

  @include layout-breakpoint('L') {
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 50px;
  }
}

.search-label {
    color: $dark-gray;
    font-family: 'BrandonGrotesque-Bold';
    font-size: 17px;
  }

.sponsored-message {
  color: $dark-gray;
  font-family: 'BrandonGrotesque-Bold';
  font-size: 22px;
  font-weight: 500;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
}

.MuiFormGroup-root {
    flex-direction: row !important;
  
    &.genderfilter {
      justify-content: center;
    }
  }
