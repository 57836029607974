@import '../../themes/pf-christmas.scss';

.offline-component {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 500px;
  padding: 50px;
  text-align: center;

  .header-second {
    color: $primary-color;
    flex: 1;
    font-size: 40px;
    font-weight: bold;
    padding-top: 30px;
  }

  .logo {
    max-height: 250px;
  }

  .sub-text {
    color: $primary-color;
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    .header-second {
      font-size: 24px;
    }
  }

  @media (max-width: 800px) {
    .header-second {
      font-size: 22px;
    }
  }

  @media (max-width: 434px) {
    .header-second {
      font-size: 20px;
    }
  }

  @media (max-width: 410px) {
    .header-second {
      font-size: 19px;
    }
  }

  @media (max-width: 388px) {
    .logo {
      height: 140px;
    }
    .header-second {
      font-size: 18px;
    }
  }

  @media (max-width: 372px) {
    .logo {
      height: 130px;
    }
    .header-second {
      font-size: 17px;
    }
  }

  @media (max-width: 357px) {
    .header-second {
      font-size: 16px;
    }
  }
}
