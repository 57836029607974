@import './auth/auth.scss';
@import './children/children.scss';
@import './review-basket/review-basket.scss';
@import './payment/payment.scss';
@import './exit/exit.scss';

.empty-container-text-style {
  font-family: $primary-font-family;
  font-size: $fs-h6;
  color: $secondary-color;
}

.button-loading {
  i {
    font-size: 30px;
  }
}
