$descDimTitle: rgba(202, 208, 210, 255);
$descDimText: rgba(164, 157, 151, 255);

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$fs-lg-h1: $font-size-base * 5 !default; // 80px
$fs-lg-h2: $font-size-base * 4.375 !default; // 70px
$fs-lg-h3: $font-size-base * 4.0625 !default; // 65px
$fs-lg-h4: $font-size-base * 3.4375 !default; // 55px
$fs-lg-h5: $font-size-base * 3.125 !default; // 50px
$fs-lg-h6: $font-size-base * 2.8125 !default; // 45px

$fs-h1: $font-size-base * 2.5 !default; // 40px
$fs-h2: $font-size-base * 2.185 !default; // 35px
$fs-h3: $font-size-base * 1.875 !default; // 30px
$fs-h4: $font-size-base * 1.75 !default; // 28px
$fs-h5: $font-size-base * 1.625 !default; // 26px
$fs-h6: $font-size-base * 1.5 !default; // 24px

$fs-p1: $font-size-base * 1.375 !default; // 22px
$fs-p2: $font-size-base * 1.25 !default; // 20px
$fs-p3: $font-size-base * 1.125 !default; // 18px
$fs-p4: $font-size-base * 1 !default; // 16px
$fs-p5: $font-size-base * 0.875 !default; // 14px
$fs-p6: $font-size-base * 0.75 !default; // 12px
