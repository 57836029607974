@import './colors.scss';

$theme-path: 'pfchristmas';
$primary-color: $joy-red;
$secondary-color: $christmas-green;
$tertiary-color: $white;

$dark-gray: $type-gray;
$middle-gray: $committed-gray;
$light-gray: $safe-haven-gray;

$dark-green: $christmas-green;

$primary-font-family: 'BrandonGrotesque', Arial;
$secondary-font-family: 'Oswald', Arial;
