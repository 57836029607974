.footer-offset {
  height: 100px;
  width: 100%;
}
@media (max-width: 1023px) {
  .footer-offset {
    height: 120px;
    width: 100%;
  }
}

.footer-container {
  font-family: $primary-font-family;
  font-weight: bold;
  display: flex;
  font-size: 20px;
  align-items: center;
  background-color: $tertiary-color;
  padding: 1.5rem;
  color: $type-gray;
  width: 100%;
  position: fixed;
  bottom: 0px;
  box-shadow: 0 0 9px -2px $type-gray;
  z-index: 999;
  @media (min-width: 1024px) {
    &.footer-container-mobile {
      display: none;
    }
  }
  @media (max-width: 1023px) {
    &.footer-container-desktop {
      display: none;
    }
  }

  .container-link {
    flex-basis: 0;
    flex-grow: 1;

    &.first {
      text-align: right;
    }

    &.second {
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
    }
  }
  .container-copy {
    flex-grow: 0;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  }

  .footer-link {
    text-decoration: underline;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    cursor: pointer;
    color: $type-gray;
  }

  .flex-container {
    flex-direction: column;
  }
}
@media (max-width: 1023px) {
  .footer-container {
    padding: 0;
  }
}
