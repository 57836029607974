.payment-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-text-container {
        border-bottom: 1px solid lightgray;
        width: 90vw;

        @include layout-breakpoint('L') {
            width: 35vw;
          }

        p {
            padding-top: 5vh;
            padding-bottom: 5vh;
            font-size: 22px;
            font-family: 'BrandonGrotesque-Bold';
            text-align: center;
            width: 80vw;

            @include layout-breakpoint('L') {
                width: 35vw;
              }
        }
    }

    .payment-information-container {

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid lightgray;
        width: 90vw;

        @include layout-breakpoint('L') {
            width: 35vw;
          }

        &__header {
            @extend .payment-information-container;
            font-family: 'BrandonGrotesque-Thin' 'Ariel';
            font-size: 23px;
            color: $joy-red;
            text-align: left;
            padding-top: 5vh;
            border-bottom: 0px;
        }
        
        &__method {
            @extend .payment-information-container;
            font-family: 'BrandonGrotesque-Bold';
            padding-left: 2vw;
            font-size: 20px;
            color: $type-gray;
            text-align: left;
            border-bottom: 0px;
        }

        &__button-container {
            @extend .payment-body-container;
            width: 85vw;
            padding-left: 2vw;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 0px;

            @include layout-breakpoint('L') {
                width: 32.75vw;
              }
            
            button {
                all: unset;
                width: 40vw;
                cursor: pointer;

                @include layout-breakpoint('L') {
                    width: 15vw;
                  }
            }

            .button-size {
                width: 3vw;
                height: auto;
            }

            .cc-button {
                border-bottom: 5px solid $joy-red;
                border-right: 1px solid $joy-red;
            }

            .bank-button {
                border-bottom: 5px solid $joy-red;
                border-left: 1px solid $joy-red;
            }

            .image-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40vw;
                height: auto;

                @include layout-breakpoint('L') {
                    width: 15vw;
                    height: auto;
                  }
            }

            .image-container img {
                width: 60%;
                height: auto;

                @include layout-breakpoint('L') {
                    width: 40%;
                    height: auto;
                  }
            }
        }

        &__cc-form-field {
            padding-left: 2vw;
            border-bottom: 1px solid lightgray;
            padding-bottom: 5vh;
            width: 90vw;

            @include layout-breakpoint('L') {
                width: 31vw;
              }

            .expiration-date-container {

                @include layout-breakpoint('L') {
                    width: 31vw;
                    display: flex;
                    flex-direction: row;
                    padding-top: 2vh;
                    justify-content: space-between;
                    :last-child {
                        width: 7vw;
                        margin: 0;
                    }
                  }
            
            .expiration-date {
                width: 85vw;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @include layout-breakpoint('L') {
                    width: 31vw;
                    padding-right: .5vw;
                    :first-child {
                        margin-right: .5vw;
                    }
                  }

                select {
                    color: $type-gray;
                    font-family: 'BrandonGrotesque-Bold';
                    font-size: 15px;
                    width: 42vw;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 7px;
                    border-radius: 5px;

                    @include layout-breakpoint('L') {
                        width: 7vw;
                      }
                }
            }
            
            }

            

            p {
                @extend .payment-information-container;
                font-family: 'BrandonGrotesque-Bold';
                font-size: 20px;
                color: $joy-red;
                padding-top: 10px;
                border-bottom: 0px;

                @include layout-breakpoint('L') {
                    font-size: 15px;
                  }
            }

            input {
                color: $type-gray;
                font-family: 'BrandonGrotesque-Bold';
                font-size: 15px;
                width: 85vw;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 10px;
                border-radius: 5px;
                border-width: 1px;
                margin-top: 2vh;

                @include layout-breakpoint('L') {
                    width: 31vw;
                  }
                :first-child {
                    width: 85vw;
                    margin-top: 10px;
                }
                :last-child {
                    padding-bottom: 5vh;
                }
            }
        }

        &__bank-account-form-field {
            
            padding-left: 2vw;
            border-bottom: 1px solid lightgray;
            padding-bottom: 5vh;
            width: 90vw;

            @include layout-breakpoint('L') {
                width: 31vw;
              }

            input, select {
                color: $type-gray;
                font-family: 'BrandonGrotesque-Bold';
                font-size: 15px;
                width: 85vw;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
                border-width: 1px;
                margin-top: 2vh;

                @include layout-breakpoint('L') {
                    width: 31vw;
                  }
                :last-child {
                    padding-bottom: 5vh;
                }
            }

            input {
                padding-left: 10px;
            }

            select {
                padding-left: 7px;
            }

        }
    }

    .user-information-container {

        &__user-info {
            display: flex;
            flex-direction: column;
            padding-left: 2vw;
            border-bottom: 1px solid lightgray;

            &__name {
                display: flex;
                flex-direction: row;
                width: 85vw;
                :first-child {
                    width: 42vw;
                    margin-right: .5vw;
                }
                :last-child {
                    width: 42vw;
                    margin-left: .5vw;
                }

                @include layout-breakpoint('L') {
                    width: 31vw;
                    :first-child {
                        width: 15vw;
                    }
                    :last-child {
                        width: 15vw;
                    }
                  }
            }

            input, select {
                color: $type-gray;
                font-family: 'BrandonGrotesque-Bold';
                font-size: 15px;
                width: 85vw;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
                border-width: 1px;
                margin-bottom: 2vh;

                @include layout-breakpoint('L') {
                    width: 31vw;
                  }
            }

            input {
                padding-left: 10px;
            }

            select {
                padding-left: 7px;
            }
            &__zipcode {
                margin-bottom: 5vh;
            }
        }
    }

    .donation-entity-container {
        border-bottom: 1px solid lightgray;
        padding: 5vh 2vw;
        width: 90vw;

        @include layout-breakpoint('L') {
            padding: 5vh 3vw;
            width: 35vw;
          }
    }

    .payment-footer-container {
        display: flex;
        flex-direction: column;
        padding-top: 5vh;
        align-items: center;
        width: 90vw;

        @include layout-breakpoint('L') {
            width: 31vw;
          }

        .donate-button-container {

            @include layout-breakpoint('L') {
                width: 31vw;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
        }

        .donation-warning {
            display: flex;
            flex-direction: row;
            
            p {
                text-align: center;
                font-family: 'BrandonGrotesque';
                font-size: 15px;
            }
        }

        .donation-button {
            display: flex;
            justify-content: center;
            padding-top: 3vh;
            padding-bottom: 5vh;
            @include layout-breakpoint('L') {
                align-items: center;
                width: 10vw;
              }
            
            p {
                text-align: center;
                font-family: 'BrandonGrotesque';
                font-size: 25px;
            }
        }

        .secure-donation-image-container {
            display: flex;
            justify-content: center;
            padding-bottom: 5vh;
            @include layout-breakpoint('L') {
                width: 20vw;

                img {
                    width: 15vw;
                }
              }
        }

        .how-to-donate-container {
            display: flex;
            flex-direction: column;
            background-color: #a49d97;
            padding-top: 3vh;
            padding-bottom: 3vh;
            padding-left: 5px;
            padding-right: 5px;

            .donation-text {
                color: $white;
                font-size: 20px;
                text-align: center;

            }
        }

        .financial-accountability-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 3vh;
            padding-bottom: 10vh;
            width: 90vw;

            @include layout-breakpoint('L') {
                width: 31vw;
            }

            .accountability-assets {
                display: flex;
                justify-content: center;
                padding-bottom: 3vh;
                
                .guidestar {
                    height: 7vh;
                    margin-top: .5vh;
                    @include layout-breakpoint('L') {
                        height: 11.5vh;
                        width: 9vw;
                        padding-top: 1vh;
                        margin-top: 0;
                    }
                }
                .ecfa {
                    height: 8vh;
                    @include layout-breakpoint('L') {
                        height: 12vh;
                        width: 10vw;
                    }
                }
                .accredited-charity {
                    height: 7vh;
                    padding-left: 3vw;
                    margin-top: .5vh;
                    @include layout-breakpoint('L') {
                        height: 11vh;
                        width: 17vw;
                        padding-left: 1vw;
                        padding-top: 1vh;
                        margin-top: 0;
                    }
                }
                &__cfc-image {
                    @extend .accountability-assets;
                    
                    img {
                        width: 45vw;
                        @include layout-breakpoint('L') {
                            width: 10vw;
                        }
                    }
                }
            }

            p {
                text-align: center;
                font-family: 'BrandonGrotesque-Bold';
                font-size: 20px;
                padding-top: 1vh;
                padding-bottom: 1vh;
            }

            .titles {
                font-size: 24px;
            }
        }
    }
}

.desktop-payment-container {

    position: relative;
    img {
        width: 100%
    }

    &__floating-container {

        position: absolute;
        background-color: $white;
        width: 40vw;
        height: auto;
        border: 1px solid lightgray;
        border-radius: 20px;
        left: 11vw;
        top: 5vh;
    }
}
