.card-btn {
  background: $primary-color;
  border-color: $primary-color !important;
  color: white;
  font-weight: 500 !important;
  font-size: 16px;
  border-radius: 5px !important;
  text-transform: uppercase !important;
  padding: 6px 25px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  height: auto !important;
  text-decoration: none !important;
  margin-bottom: 20px !important;

  button:focus {
    border-color: $primary-color !important;
  }

  button:active {
    border-color: $primary-color !important;
  }
}

.card-btn-color {
  color: $primary-color !important;
}
