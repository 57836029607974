$header-height: 78px;

.angel-tree-logo {
  height: 48px;
  flex-shrink: 0.7;
}

.cart-button--mobile {
  background: transparent;
  border: none;
}

.cart-indicator {
  position: relative;

  &__icon-cart {
    width: 31.5px;
    height: 30px;
  }

  &__notification-badge {
    background-color: $joy-red;
    color: white;
    line-height: 17px;
    border-radius: 16px;
    padding: 0 2px;
    font-family: 'BrandonGrotesque';
    font-size: 14px;
    min-width: 17px;
    position: absolute;
    right: -6px;
    text-align: center;
    top: -1px;
  }
}

.nav-link {
  background: transparent;
  border: none;
  color: white;
  font-family: 'BrandonGrotesque';
  font-size: clamp(18px, 5vw, 22px);
  font-weight: lighter;
  text-align: right;
  text-decoration: underline;

  @include layout-breakpoint('L') {
    text-align: center;
  }
}

.header {
  align-items: center;
  background-color: $secondary-color;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-height: $header-height;
  justify-content: space-between;
  padding: 10px 8vw;
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: 999; // TODO :: high z-index values of other app styles makes this necessary; should be reworked

  @include layout-breakpoint('L') {
    gap: 80px;
    padding: 10px 11vw;
  }

  &--as-footer {
    top: unset;
    bottom: 0;
    gap: 40px;
    position: fixed;
  }

  &--center-content {
    justify-content: center;
  }

  &__title {
    color: white;
    font-size: 1.22rem;
    font-family: 'BrandonGrotesque-Bold';
    margin-bottom: 0;
    text-align: center;

    @include layout-breakpoint('L') {
      font-size: clamp(0.3rem, 2vw, 1.8rem);
      line-height: 2.5vw;
    }
  }
}

.header-mobile {
  align-items: center;
  background-color: $joy-red;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
  position: static;
}
