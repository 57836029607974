.pf-button {
  align-items: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  flex-direction: row;
  font-size: 17px;
  font-family: 'BrandonGrotesque-Bold';
  gap: 10px;
  height: 45px;
  justify-content: space-evenly;
  min-width: 223px;
  padding: 0 5px;
  text-align: center;
  text-transform: uppercase;

  &--solid-primary {
    background: $primary-color;
    border-color: $primary-color;
    color: white;
  }

  &--transparent-primary {
    background: transparent;
    border-color: $primary-color;
    color: $primary-color;
  }

  &--transparent-light {
    background: transparent;
    border-color: white;
    color: white;
  }

  &--transparent-gray {
    background: transparent;
    border-color: $type-gray;
    color: $type-gray;
  }
}
