.ornament-container {
    display: flex;
    flex-direction: row;
    top: -60px;
    left: -125px;
    position: absolute;
    
    .red-ornament {    
        position: fixed;
        max-width: 100%;
        height: auto;
        right: 24vw;
    }
    .blue-ornament {
        position: fixed;
        max-width: 100%;
        height: auto;
        right: 13vw;
    }
}

.review-basket-container {
    display: flex;
    flex-direction: column;
    padding-top: 5vh;
    overflow: hidden;

    .review-basket-header-container {
        border-bottom: 1px solid lightgray;
        margin-left: 5vw;
        margin-right: 5vw;
        padding-top: 4vh;
        
        @include layout-breakpoint('L') {
            width: 60vw;
        }

        .review-basket-header {
            padding-left: 5vw;
            padding-right: 5vw;
            width: 90vw;

            p {
                font-family: $primary-font-family;
                font-weight: bold;
                font-size: 23px;
                color: black;
                text-align: left;
                margin-top: 5vh;
                width: 70vw;

                @include layout-breakpoint('M') {
                    width: 55vw;
                }
            }
        }    
    }
    

    .review-basket-tag-label-container {

        .review-basket-tag-label-box {
            display: flex;
            align-items: flex-end;
            align-content: center;
            margin-right: 5vw;
            margin-left: 5vw;
    
            p {
                color: black;
                font-family: $primary-font-family;
                font-size: 18px;
                padding-top: 5vh;
                margin-bottom: 0;
            }
    
            &__label-one {
                text-align: center;
                width: 48vw;
                @include layout-breakpoint('M') {
                    width: 27vw;
                }
            }
            &__label-two {
                width: 20vw;
                text-align: center;
                @include layout-breakpoint('M') {
                    width: 10vw;
                }
            }
            &__label-three {
                width: 20vw;
                padding-left: 5vw;
                @include layout-breakpoint('M') {
                    width: 10vw;
                }
            }
        }


    }

    

    .review-basket-contents {

        display: flex;
        align-items: flex-end;
        margin-right: 5vw;
        margin-left: 5vw;

        .child-donation-list-container {
            padding-bottom: 40vh;

            .child-donation-item-container {
                display: flex;
                flex-direction: row;

                .close-button-container {
                    all: unset;
                    cursor: pointer;
                    display: flex;
                    margin-right: 3vw;
                    margin-top: 1.5vh;

                    @include layout-breakpoint('L') {
                        margin-left: 2vw;
                        margin-top: 2.5vh;
                    }

                    .close-button {
                        font-size: 28px;
                    }
                    
                }


                .child-donation-list-row {
                    display: flex;
                    border: 1px solid lightgray;
                    border-radius: 15px;
                    background-color: #fff;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0;
                    margin-top: 1vh;
                    margin-bottom: 1vh;
                    width: 85vw;

                    @include layout-breakpoint('M') {
                        padding-top: 1vh;
                        padding-bottom: 1vh;
                    }

                    @include layout-breakpoint('L') {
                        width: 50vw;
                    }
                    
          
                    .tag-item-column {
                        display: flex;
                        align-items: center;
                        width: 39vw;

                        .child-header-image {
                            position: relative;
                            width: 8vw;
                            top: 0px;
                            left: 0;
                            box-shadow: 0 0 1px -3px $primary-color;
                            border-radius: 40px;
                          
                            @include layout-breakpoint('L') {
                              top: 0px;
                              position: relative;
                              left: initial;
                            }
                          }
    
    
                        p {
                            font-family: 'BrandonGrotesque-Bold';
                            font-weight: bold;
                            padding-left: 2vw;
                            font-size: 16px;
                            text-transform: uppercase;
                            color: $secondary-color;
                            width: 18vw;

                            @include layout-breakpoint('M') {
                                font-size: 22px;
                            }
                        }
        
                    }
    
                    .children-per-tag-column {   
                        width: 20vw;                     
    
                        p {
                            font-family: 'BrandonGrotesque-Bold';
                            border-left: 1px solid lightgray;
                            border-right: 1px solid lightgray;
                            padding-left: 5vw;
                            padding-right: 5vw;
                            text-align: center;
                        }
                    }
    
                    .price-column {
                        width: 20vw;
                        padding-left: 6vw;
    
                        p {
                            font-family: 'BrandonGrotesque-Bold';
                            font-weight: bold;
    
                        }
                    }
                }
            }
            
            
            
        }

        .review-basket-payment-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: $white;
            color: white;
            align-content: center;
            text-align: center;
            border-top: 5px solid lightgray;

        @include layout-breakpoint('L') {
            border: 1px solid lightgray;
            border-radius: 15px;
            width: 260px;
            max-height: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: fixed;
            left: 65vw;
            top: 300px;
          }

        .button-box {
            display: flex;
            justify-content: center;
        }
        

        .total-amount-text {
            font-family: "BrandonGrotesque", Arial;
            font-size: 25px;
            font-weight: 700;
            margin-top: 2vh;
            text-transform: uppercase;
            color: black;
            
            
            @include layout-breakpoint('L') {
                font-family: "BrandonGrotesque", Arial;
                font-size: 25px;
                font-weight: 700;
                padding: 15px 0px;
                text-transform: uppercase;
                color: black;
                text-align: center;
            }
        }

        .add-child-text {

            font-family: "BrandonGrotesque", Arial;
            font-size: 19px;
            font-weight: 700;
            padding: 15px 0px;
            color: red;
            text-align: center;
            text-decoration: underline;
            cursor: pointer;

            @include layout-breakpoint('L') {
                font-family: "BrandonGrotesque", Arial;
                font-size: 19px;
                font-weight: 700;
                padding: 15px 0px;
                color: red;
                text-align: center;
                text-decoration: underline;
                cursor: pointer;
            }

        }
        
        button {
            display: flex;
            background-color: $joy-red;
            color: $white;
            width: 15vw;
        }
    }

            

        
    }
}
