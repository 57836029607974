@import './themes/fonts.scss';
@import './components/genderfilter/index.scss';

.Toastify__close-button--default {
  color: #fff !important;
  opacity: 1 !important;
}

.Toastify__toast--default {
  background: #000 !important;
  color: #fff !important;
}

.Toastify__toast {
  min-height: 60px !important;
  border-radius: 10px !important;
  align-items: center;
  font-family: $primary-font-family;
  font-weight: 500;
  font-size: $fs-p3;
}

.Toastify__toast--error {
  background: $primary-color !important;
}

.Toastify__toast--info,
.Toastify__toast--success {
  background: $secondary-color !important;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.Toastify__progress-bar {
  opacity: 0.7 !important;
}

.user-info-modal {
  .modal-content {
    border-radius: unset;
    width: 420px;

    @media only screen and (max-width: 575px) {
      width: 100%;
    }

    .modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .message {
        font-size: $fs-p3;
        color: $secondary-color;
        font-weight: 500;
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.5;
}
.modal-dialog {
  max-width: 440px;
}
.modal-title {
  font-size: 1.4em;
}
.modal-header {
  border-bottom: none;
  text-align: center;
  display: block;
}
.modal-content {
  padding: 25px;
  border-radius: 0.8rem;
}
.modal-title {
  line-height: 1.5;
  font-size: 1.5em;
  color: #615a5a;
  font-family: $primary-font-family;
}
.code-container {
  .modal-content {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .modal-content {
    width: 135%;
  }
}
@media (max-width: 575px) {
  .modal-dialog {
    padding-left: 20px;
    padding-right: 20px;
  }
  .success-btn {
    width: 189px;
  }
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 374px) {
  .modal-dialog {
    padding-left: 11px;
  }
}
