.react-select {
  width: 100%;
  font-family: $primary-font-family;

  .css-1uccc91-singleValue {
    font-size: $font-size-base !important;
    color: black !important;
  }

  .css-26l3qy-menu {
    font-size: $fs-p6 !important;
    font-weight: bold;
    color: black !important;
    text-transform: uppercase;
    z-index: 6;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1hwfws3,
  .css-1fhf3k1-control,
  .css-1hb7zxy-IndicatorsContainer {
    min-height: 29px;
    height: auto;
    max-height: 37px;
  }

  .css-yk16xz-control,
  .css-1pahdxg-control {

    border: none !important;
    border-bottom: 1px solid #58595b !important;

    &:hover {
      border: 2px solid black;
    }
  }

  .css-1hwfws3 {
    $background-color: $tertiary-color;
    border-radius: 0;

    &:focus {
      outline: unset;
    }
  }

  .css-1wa3eu0-placeholder {
    font-family: $primary-font-family;
    font-weight: bold;
    font-size: $fs-p6;
    text-align: left;
    text-transform: uppercase;
    color: black;
  }

  .css-1okebmr-indicatorSeparator {
    margin: 0 0;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    padding: 0;
  }
}

.custom-error-select-border {
  .css-yk16xz-control {
    border-radius: 0;
  }
}

.error-text {
  font-size: $fs-p5;
  color: $primary-color;
  text-align: left;
  $background-color: $tertiary-color;
}
.react-select .css-1wa3eu0-placeholder {
  color: $type-gray !important;
}
.css-yk16xz-control {
  border-radius: unset !important;
}
.css-1okebmr-indicatorSeparator{
  display: none;
}
