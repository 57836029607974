.children-and-filter-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 0 20px 5vh 20px;

  @include layout-breakpoint('L') {
    padding: 0 13vw 5vh 13vw;
  }
}

.child-description {
  text-overflow: ellipsis;
}

.empty-gifts {
  @extend .empty-container-text-style;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #cc002b;
  color: white;
  height: 80px;
  width: 275px;
  bottom: 0;
  position: fixed;
  border-radius: 15px 15px 0 0;
  padding: 0 20px;
}

.tag-text {
  height: 22px;
  width: 70%;
  margin-right: 20px;
}

.tag-image1 {
  height: 35px;
  margin-top: 13px;
  cursor: pointer;
}

.tag-image2 {
  height: 12px;
  margin-left: 79%;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.baseline {
  align-items: baseline;
  margin-bottom: -12px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.image {
  height: 55px;
  width: 45px;

  @media screen and (max-width: 550px) {
    height: 45px;
    width: 35px;
  }
}

.child-tag {
  color: #89b1b1;
}

.children-list-container {
  display: flex;
  margin-top: 0.5em;
  margin-bottom: 20px;

  @include layout-breakpoint('L') {
    margin-bottom: 0;
  }
}

.default,
.text {
  padding-top: 8px !important;
}

.search-children {
  display: flex;
  justify-content: center;
}

.submit-container {
  display: flex;
  justify-content: center;
  padding-top: 80px;
}

.outlined-angel {
  height: 55px;
  position: absolute;
  margin: 32px 0px 0px 25px;
}

@media (max-width: 532px) {
  .company-name {
    font-size: 1.2em;
  }

  .profile-img {
    width: 96px;
    height: 96px;
  }
}

.loading-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-loading {
  display: flex;
  z-index: 1500;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  i {
    display: block;
    position: absolute;
  }
}

.no-record {
  text-align: center;
  margin-top: 10px;
}

.position-relative {
  position: relative;
}

.result-fade {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.3;
    z-index: 100;
  }
}

@mixin buttonTheme {
  background-color: $primary-color;
  color: white;
  border-color: $primary-color;
}

.checkout-mode-options-modal {
  font-family: $primary-font-family;
  text-align: center;
  z-index: 100;
  background-color: white;
  border: 1px solid red;
  padding: 2rem;
  margin-top: 15vh;
  width: 90vw;

  &.next {
    padding: 1.5rem;
  }
  
  @include layout-breakpoint('M') {
    width: auto;
  }

  .parentDiv {
    max-width: 500px;
  }

  h2 {
    color: $primary-color;
  }

  h4 {
    color: $secondary-color;
    margin-top: 2rem;
  }

  .detail-group {
    margin-top: 2rem;
    font-size: 18px;

    .textField {
      width: 40px;

      input, label {
        color: white;
      }

      div {
        &::before, &::after {
          border-bottom-color: white;
        }
      }
    }

    .label-sponsor {
      margin-top: 1.75rem;
      margin-right: 5px;
    }

    .label-dollar {
      margin-top: 1.75rem;
    }

    .option-group {
      display: flex;
      gap: 20px;
      justify-content: center;
      background-color: $primary-color;
      color: white;
      padding: 1rem;
    }

    .btn {
      &-submit {
        margin-top: 2rem;
        display: flex;

        .icon-back {
          color: $primary-color;
          flex: 1;
          text-align: left;

          svg {
            cursor: pointer;
          }
        }

        button {
          @include buttonTheme;
        }
      }
    }
  }


  .btn-group {
    display: flex;
    margin-top: 2rem;
    @media screen and (min-width: 320px) and (max-width: 500px) {
      flex-direction: column;
    }

    .btn {
      button {
        height: 100px;
        width: 180px;
        @include buttonTheme;
      }
    }
  }
}

.empty-modal-message {
  color: #1e7e34;
  font-size: 1.3rem;
}

.error-modal-button {
  @include buttonTheme;
  padding: 10px;
  margin: 5px;

}

.age-range-message {
    text-align: center;
    padding-top: 3vh;
    font-family: $primary-font-family;
    color: $secondary-color;
    font-size: 22px;

    @include layout-breakpoint('L') {
        font-size: 28px;
    }
}

.checkout-mode-modal {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;

  .modal-content {
    align-items: center;
    flex: 1 1 auto;
    border: none;
    border-radius: unset;
    font-family: $primary-font-family;
    background: none;

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      margin: auto;

      .common-margins {
        margin: 20px 0;
      }

      .s-info {
        text-align: center;
        font-size: $fs-h4;
        color: $primary-color;
        text-transform: uppercase;
        line-height: 1.1;
        font-family: $primary-font-family;
        margin-top: 0;

        .name {
          font-weight: bold;
        }

        .count {
          font-weight: 400;
          font-size: $fs-p1;
        }
      }

      .emojis {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        img {
          width: 40px;
          margin: 2px 5px;
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          border-radius: 40px;
        }
      }

      .sponsor-rate {
        text-align: center;
        font-weight: bold;
        font-size: $fs-p2;
        color: $primary-color;
      }

      .modal-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        button {
          margin: 5px 20px;
          border: 1px solid $secondary-color;
          padding: 10px 30px;
          font-family: $primary-font-family;
          font-weight: bold;
          font-size: $fs-p4;
          border-radius: 5px;
          text-transform: uppercase;
        }

        .cancel,
        .cancel:hover {
          color: $secondary-color !important;
          background: white !important;
        }

        .confirm,
        .confirm:hover {
          color: white !important;
          text-transform: uppercase;
          background: $secondary-color !important;
        }
      }
    }
  }
}

.children-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: $header-height;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  justify-content: center;

  @include layout-breakpoint('L') {
    margin-top: 18px;
    padding: 12px 0;
  }
}

@media (max-width: 480px) {
  .footer-offset {
    height: 100px;
  }
}

.praise-the-lord {
  color: $primary-color;
  font-family: $primary-font-family;
  font-size: 25px;
  font-weight: 500;
}

.all-children-sponsored {
  color: $secondary-color;
  font-family: $primary-font-family;
  font-size: 25px;
  font-weight: 500;
}

.check-national-org {
  color: $primary-color;
  font-family: $primary-font-family;
  font-size: 25px;
  font-weight: 500;
  margin-top: 20px;

  .unmatched-page {
    color: $secondary-color;
    text-decoration: underline;
  }
}

.no-record {
  text-align: center;
  margin-top: 10px;
  padding: 10px 0px 20px 0px;
}

.child-render-inner-container {
  box-shadow: 0 0 5px 2px $primary-color;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  width: 97%;
  margin: 1rem auto;
}

@media (max-width: 426px) {
  .child-render-inner-container {
    box-shadow: none;
    display: block !important;
  }
}
