$fellowship-blue: #325e89;
$restoration-teal1: #58c4c7;
$default-tertiary: #fff;

$type-gray: #6e6e6e;
$committed-gray: #a7a6a2;
$safe-haven-gray: #cdd5d5;
$christmas-green: #459a51;

$joy-red: #d51c28;
$white: #fff;
//This color code base on "WEB COLORS TABLE" FROM THE PF_BrandGuide_June_2021
// $Restoration-Teal-variation-1:#479E9A;
// $Restoration-Teal-variation-2:#8FC1C1;
// $Community-Orange:#F98A3C;
// $Community-Orange-variation-1:#EB6E0F;
// $Community-Orange-variation-2:#BB580C;
// $Hope-Yellow:#FDD44D;
// $New-Life-Green:#8FD16A;
// $Black_seventy_percent:#676C73;
// $Safe-Haven-Grey:#CDD5D5;
// $Section-Separation-Background:#EFEFEF;
