.auth-modal {
  .modal-content {
    border-radius: unset;
    font-family: $primary-font-family;
    width: 450px;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
    .auth-heading {
      font-size: $fs-h3;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      color: $primary-color;
    }
    .organization-label {
      font-weight: 400;
      text-align: center;
      font-size: $fs-p1;
      color: $secondary-color;
      margin: 1.5em auto;
    }
    .auth-body {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      @media only screen and (max-width: 575px) {
        flex-wrap: wrap;
      }

      .body-desc {
        width: 11em;
        text-align: end;
        color: $primary-color;

        @media only screen and (max-width: 768px) {
          width: 100%;
          text-align: center;
          margin-bottom: 5px;
        }
      }
      .auth-action {
        display: flex;
        flex-direction: column;
        margin-left: 2em;
      }
    }
  }
}
