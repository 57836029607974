.exit-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5vh 5vw;
  gap: 20px;

  @include layout-breakpoint('L') {
    flex-direction: row;
    justify-content: space-around;
    padding: clamp(190px, 15%, 18vh) 6vw;
    gap: 10vw;
  }

  .child-image {
    width: 100%;
    height: auto;

    @include layout-breakpoint('L') {
      flex: 1 1 0;
      width: unset;
      height: unset;
    }
  }

  .exit-inner-container {
    flex: 1.5 1 0;

    .heading {
      color: $secondary-color;
      font-family: 'BrandonGrotesque-Bold';
      font-size: clamp(2rem, 3vw, 4.2rem);
      text-align: left;
    }

    .exit-content {
      margin-top: 30px;

      .thanks-text {
        margin-top: 10px;
        font-family: 'BrandonGrotesque';
        font-size: clamp(1rem, 2.5vw, 1.85rem);
        color: black;
        text-align: left;
      }

      .email-content {
        margin-top: 20px;
        font-family: 'BrandonGrotesque-Bold';
        font-size: clamp(1rem, 2.5vw, 1.85rem);
        text-align: left;
        word-wrap: break-word;
      }
    }
  }

    .red-ornament {
        position: absolute;
        top: -450px;
        left: 15px;
        max-width: 100%;
        height: auto;

        @include layout-breakpoint('L') {
          left: unset;
          right: 15vw;
        }
    }
    .blue-ornament {
      position: absolute;
      max-width: 100%;
      height: auto;
      top: -10px;
      right: 10px;

      @include layout-breakpoint('L') {
        top: -30px;
        right: 6vw;
      }
    }
}
